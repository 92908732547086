/* global dataLayer */
import appContext from '../appContext';

window.dataLayer = window.dataLayer || [];

const productPositions = {};
let positionCount = 0;
let purchaseCallback;

const getCurrentPath = () => window.location.href.replace(window.location.origin + appContext, '');

function pushComplete(redirectUrl, callback) {
    if (callback) {
        callback();
    }
    if (redirectUrl) {
        window.location.href = redirectUrl;
    }
}

function pushData(data, redirectUrl, callback) {
    if (redirectUrl || callback) {
        data.eventTimeout = 1000;
        data.eventCallback = (id) => {
            // checking for and id that starts with GTM- makes sure we only trigger one eventCallback
            // for the GTM container while ignoring any other possible containers
            if (id.startsWith('GTM-')) {
                pushComplete(redirectUrl, callback);
            }
        };
    }
    data.language = 'English';
    dataLayer.push(data);
}

function buildProductFieldObject(worksheetDetail) {
    return {
        id: worksheetDetail.line + worksheetDetail.itemNumber,
        name: worksheetDetail.itemDescription,
        category: worksheetDetail.analyticsCategory,
        brand: worksheetDetail.line ? worksheetDetail.line : '',
        price: worksheetDetail.itemCost,
        quantity: worksheetDetail.itemQuantity,
    };
}

function buildProductFieldObjectList(quoteItems) {
    const productList = [];
    for (const prop in quoteItems) {
        if (quoteItems.hasOwnProperty(prop)) {
            productList.push(buildProductFieldObject(quoteItems[prop]));
        }
    }
    return productList;
}

function buildProductImpressionObj(cp, category) {
    const id = cp.catalogKey.groupId + cp.catalogKey.itemId;

    if (productPositions[id] === undefined) {
        positionCount += 1;
        productPositions[id] = positionCount;
    }

    return {
        id,
        name: cp.itemDescription,
        list: cp.productOrigin,
        brand: cp.catalogKey.groupId,
        category,
        position: productPositions[id],
        price: cp.itemCost,
    };
}

function buildProductDetailObj(cp, partTypeStr) {
    const id = cp.catalogKey.groupId + cp.catalogKey.itemId;
    return {
        id,
        name: cp.itemDescription,
        brand: cp.catalogKey.groupId,
        category: partTypeStr,
        price: cp.itemCost,
    };
}

// Purchase event functions
function buildCheckoutOption(worksheetId, method) {
    return {
        event: 'checkoutOption',
        ecommerce: {
            checkout_option: {
                id: worksheetId,
                actionField: { option: method },
            },
        },
    };
}

function pushPurchaseData(quoteData) {
    const data = {
        event: 'purchase', // has to have an event key to use the callback
        ecommerce: {
            currencyCode: 'USD',
            purchase: {
                actionField: {
                    id: quoteData.worksheetForm.headerId,
                    affiliation: quoteData.currentShop.arAccountNumber,
                    revenue: quoteData.worksheetForm.totalCostOrder,
                    tax: quoteData.worksheetForm.taxSubtotal,
                },
                products: [],
            },
        },
    };
    data.ecommerce.purchase.products = buildProductFieldObjectList(quoteData.quoteItems);
    pushData(data, false, purchaseCallback);
}

function pushPaymentOption(quoteData) {
    const val = quoteData.worksheetForm.cash ? 'cash' : 'charge';

    // push payment option and then purchase data
    pushData(buildCheckoutOption(quoteData.worksheetForm.headerId, val), false, () => {
        pushPurchaseData(quoteData);
    });
}

function pushDeliveryOption(quoteData) {
    const val = quoteData.worksheetForm.delivery ? 'delivery' : 'pickup';
    pushData(buildCheckoutOption(quoteData.worksheetForm.headerId, val), false, () => {
        pushPaymentOption(quoteData);
    });
}

function analyticsLoaded() {
    return window.dataLayer.findIndex((gtmEvent) => gtmEvent.event === 'gtm.load') > -1;
}

export function addGlobalData({ accountId, globalFeatures, featuresForStore }) {
    pushData({
        accountId,
        globalFeatures,
        featuresForStore,
    });
}

/* expose this function so that we can queue analytics event without analytics being loaded yet */
export function searchAction(options) {
    const { usedTypeAhead, query, accountId } = options.data;

    pushData(
        {
            event: 'search',
            eventCategory: 'Search Performed',
            eventLabel: query,
            eventAction: usedTypeAhead ? 'with typeahead' : 'without typeahead',
            accountId,
        },
        null,
        options.callback
    );
}

/* expose this function so that we can queue analytics event without analytics being loaded yet */
export function searchResultsAction(options) {
    const { resultsRetrieved, query, isPlp, accountId } = options.data;
    let eventAction = 'PLP';

    if (!isPlp) {
        eventAction = resultsRetrieved ? 'Part Type Results' : 'No Results';
    }

    pushData(
        {
            event: 'search_results',
            eventCategory: 'Search Results',
            eventLabel: query,
            eventAction,
            accountId,
        },
        null,
        options.callback
    );
}

/* expose this function so that we can queue analytics event without analytics being loaded yet */
export function currentStoreAction(options) {
    const { currentStoreId } = options.data;

    pushData(
        {
            event: 'current_store',
            eventCategory: 'Current Store',
            eventAction: 'Current Store',
            eventLabel: currentStoreId,
        },
        options.redirect,
        options.callback
    );
}

export const GTM = {
    Action: {
        VEHICLE_SELECTOR: 'vehicleSelector',
        SEARCH: 'search',
        VEHICLE_SELECTION: 'vehicleSelection',
    },
};

const getLaborTypeLabel = (isLaborGuide) => (isLaborGuide ? 'Labor Guide' : 'Custom');

const laborEvent = (eventAction, eventLabel) =>
    pushData({
        event: 'fco_gtm',
        eventCategory: 'Labor',
        eventAction,
        eventLabel,
    });

const actions = {
    // enhanced ecommerce actions
    addToCart(options) {
        pushData({
            event: 'addToCart',
            ecommerce: {
                currencyCode: 'USD',
                add: {
                    products: [options.data],
                    source: options.source,
                },
            },
        });
    },
    removeFromCart(options) {
        pushData({
            event: 'removeFromCart',
            ecommerce: {
                currencyCode: 'USD',
                remove: {
                    products: [options.data],
                },
            },
        });
    },
    productImpressions(options) {
        const productList = options.data || [];
        const impressions = [];
        let category;

        productList.forEach((partType) => {
            partType.completeProducts?.forEach((cp) => {
                if (cp.productOrigin === 'CATALOG') {
                    category = partType.partTypeName;
                } else {
                    category = cp.productOrigin;
                }
                impressions.push(buildProductImpressionObj(cp, category));
            });
        });
        const data = {
            event: 'productImpressions',
            ecommerce: {
                impressions,
            },
        };
        pushData(data);
    },
    productClick(options) {
        const gaProduct = buildProductImpressionObj(options.data, options.category);
        const data = {
            event: 'productClick',
            ecommerce: {
                products: [gaProduct],
            },
        };
        pushData(data, options.redirect, options.callback);
    },
    productDetail(options) {
        const p = buildProductDetailObj(options.data, options.category);
        const data = {
            event: 'productDetail',
            ecommerce: {
                detail: {
                    products: [p],
                },
            },
        };

        window.addEventListener('load', () => {
            pushData(data);
        });
    },
    checkout(options) {
        const quoteData = options.data;
        const data = {
            event: 'checkout',
            ecommerce: {
                checkout: {
                    products: buildProductFieldObjectList(quoteData.quoteItems),
                },
            },
        };

        // we need to wait for the page/resources to load so that the window property
        // google_tag_manager is defined.
        window.addEventListener('load', () => {
            pushData(data);
        });
    },
    purchase(options) {
        purchaseCallback = options.callback;

        // this starts an event chain that pushes the delivery option, payment option,
        // then purchase data
        pushDeliveryOption(options.data);
    },

    // end enhanced ecommerce actions
    catalogLookup(options) {
        const productTypes = options.data;
        const productTypesLength = productTypes.length;
        const lastProductType = productTypesLength - 1;
        let callback = null;

        if (productTypesLength < 1) {
            options.callback();
            return;
        }
        for (let i = 0; i < productTypesLength; i++) {
            if (i === lastProductType) {
                ({ callback } = options);
            }
            pushData(
                {
                    event: 'fco_gtm',
                    eventCategory: 'catalogLookup',
                    eventAction: productTypes[i].gaTabOrigin,

                    // package jobs don't have partTypeName, so it's been approved to just set a string of package jobs
                    eventLabel: productTypes[i].partTypeName || productTypes[i].gaTabOrigin,
                    partTypeId: productTypes[i].partTypeId,
                },
                null,
                callback
            );
        }
    },
    vehicleSelection(options) {
        pushData(
            {
                event: 'Vehicle Select',
                eventCategory: 'Vehicle Selection',
                eventAction: options.data,
                eventLabel: getCurrentPath(),
            },
            options.redirect,
            options.callback
        );
    },
    /* part searching (main searchbar) */
    search: searchAction,
    searchResults: searchResultsAction,
    currentStore: currentStoreAction,
    /* vehicle selector actions (vin contacted, option selected, etc) */
    vehicleSelector(options) {
        pushData({
            event: 'fco_gtm',
            eventCategory: 'Vehicle Selection',
            eventAction: options.data.eventAction,
        });
    },

    quotesAndOrders(options) {
        pushData(
            {
                event: 'fco_gtm',
                eventCategory: 'quotesAndOrders',
                eventAction: options.action,
                startDate: options.data.startDate,
                eventLabel: options.data.startDate,
            },
            options.redirect,
            options.callback
        );
    },
    home(options) {
        pushData({
            event: 'fco_gtm',
            eventCategory: 'orderRedirect',
            eventAction: options.data.isOrderRedirect,
            eventLabel: 'home',
        });
    },
    manageCustomers(options) {
        pushData({
            event: 'fco_gtm',
            eventCategory: 'manageCustomers',
            eventAction: options.data,
            eventLabel: getCurrentPath(),
        });
    },
    plpSort(options) {
        pushData({
            event: 'fco_gtm',
            eventCategory: 'PLP Sort',
            eventAction: options.action,
            eventLabel: getCurrentPath(),
        });
    },
    plpTableFilter() {
        pushData({
            event: 'tableFilteredPLP',
            eventCategory: 'Table Filtered',
            eventAction: 'tableFilteredPLP',
            eventLabel: getCurrentPath(),
        });
    },
    vehicleDocumentLookup(options) {
        pushData({
            event: 'fco_gtm',
            eventCategory: 'Vehicle Documents',
            eventAction: 'Document Lookup',
            documentHierarchy: options.data,
        });
    },
    showLaborForm({ data: { isLaborGuide } }) {
        laborEvent('Show Form', getLaborTypeLabel(isLaborGuide));
    },
    addLaborToQuote({ data: { isLaborGuide } }) {
        laborEvent('Add to Quote', getLaborTypeLabel(isLaborGuide));
    },
};

const analytics = function trackGoogleAnalyticsData(options) {
    const { data, event, redirect, callback } = options;
    try {
        if (analyticsLoaded() && data && event && actions[event]) {
            actions[event](options);
        } else {
            pushComplete(redirect, callback);
        }
    } catch (error) {
        // in case something goes wrong, continue flow
        pushComplete(redirect, callback);
    }
};

export default analytics;
